import { defaultProps, props } from 'containers/themes/props/store';
import { TopBar } from 'components/store/TopBar';
import { getUserIcon } from 'components/utils/icon';
import { Grid } from '@mui/material';
import StoreDescription from 'components/store/StoreDescription';
import FollowUsAndMore from 'components/shop/FollowUsAndMore';
import React from 'react';
import { useDeviceWidth } from 'hooks/removeJss';
import Catalogs from 'components/store/lilac/Catalogs';
import Templates from 'components/store/lilac/Templates';
import ViewAll from 'components/store/common/ViewAll';
import { useLanguageLoader } from 'contexts/language';
import { ProductCard } from 'components/store/lilac/ProductCard';
import { useIsDesktop } from 'contexts/profile';
import styles from './store.module.css';
import ViewAllCatalogLazy from 'components/store/common/ViewAllCatalogLazy';
import ShopUtils from 'ui/lib/shopUtils';
import DisablePopup from 'components/store/common/DisablePopup';

function LilacStore({
  shop,
  savedFeed,
  templates,
  catalogList,
}) {
  const width = useDeviceWidth();
  const shopIcon = getUserIcon(shop);
  const lang = useLanguageLoader();
  const isDesktop = useIsDesktop();
  const props = isDesktop ? [{variant:'large'}, {variant:'large'}] : [{variant:'large'}];
  const showTemplate = ShopUtils.showBanner(shop);
  const shopProfileEnabled = ShopUtils.isSectionEnabled(shop, 'shopProfile');
  const collectionsEnabled = ShopUtils.isSectionEnabled(shop, 'collections');
  const featuredProductsEnabled = ShopUtils.isSectionEnabled(shop, 'featuredProducts');

  const productsEnabled = ShopUtils.isSectionEnabled(shop, 'products');
  const feedTitle = ShopUtils.getTitle(shop, 'featuredProducts');
  const savedTitle = lang('store.headings.featuredProducts') === feedTitle ?
    lang('store.headings.featuredProducts') : feedTitle;
  const layoutConfig = shop?.uiConfig?.layoutConfig;
  const templateIdShopProfile = layoutConfig?.shopProfile?.banners?.
    flatMap(banner => Object.values(banner.templateIds)) || [];
  const foundShopProfileTemplates = templates?.filter(template => templateIdShopProfile.includes(template._id));
  const templateIdCollections = layoutConfig?.collections?.banners?.
    flatMap(banner => Object.values(banner.templateIds)) || [];
  const foundCollectionsTemplates = templates?.filter(template => templateIdCollections.includes(template._id));

  const templateIdFeaturedProducts = layoutConfig?.featuredProducts?.banners?.
    flatMap(banner => Object.values(banner.templateIds)) || [];
  const foundFeaturedProductsTemplates =
      templates?.filter(template => templateIdFeaturedProducts.includes(template._id));

  const templateIdProducts = layoutConfig?.products?.banners?.
    flatMap(banner => Object.values(banner.templateIds)) || [];
  const foundProductsTemplates = templates?.filter(template => templateIdProducts.includes(template._id));

  return (
    <div>
      <DisablePopup blocked={shop?.blocked} />
      <TopBar shop={shop} catalogs={catalogList} showTabs={false} />
      <div id="shopScroll1" />
      {shopProfileEnabled && (
        <div className="mobile">
          <div className={styles.shopIcon}>
            <img className={styles.icon} src={shopIcon} alt="" />
          </div>
        </div>
      )}

      <>
        {showTemplate && <Templates shop={shop} width={width} templates={foundShopProfileTemplates} /> }
      </>
      {shopProfileEnabled && (
        <div className="desktop">
          <div className={styles.shopIcon}>
            <img className={styles.icon} src={shopIcon} alt="" />
            <Grid container spacing={2} className={styles.shopIconGrid}>
              <Grid item xs={12} className={styles.shopName}>{shop?.name}</Grid>
              <Grid item xs={12}>
                <StoreDescription shop={shop} />
              </Grid>
              <Grid item xs={12}>
                <FollowUsAndMore shop={shop} variant="minimal" />
              </Grid>
            </Grid>
          </div>
        </div>
      )}
      {collectionsEnabled && (
        <>
          <Catalogs shop={shop} catalogs={catalogList} width={width}/>
        </>
      )}
      {showTemplate && <Templates templates={foundCollectionsTemplates} shop={shop}/>}
      {featuredProductsEnabled && (
        <ViewAll
          shop={shop}
          products={savedFeed}
          type="featured"
          name={savedTitle}
          label={`${lang('common.browse')} ${savedTitle}`}
        >
          {savedFeed.slice(0, isDesktop ? 2 : 1)
            .map(product => (
              <ProductCard key={product._id} variant="large" shop={shop} product={product} />
            ))}
          {savedFeed.slice(isDesktop ? 2 : 1, isDesktop ? 6 : 5)
            .map(product => (
              <ProductCard key={product._id} shop={shop} product={product} />
            ))}
        </ViewAll>
      )}
      {showTemplate && <Templates templates={foundFeaturedProductsTemplates} shop={shop}/>}
      {productsEnabled && (
        <>
          {catalogList.map(catalog => (
            <ViewAllCatalogLazy
              height={700}
              key={catalog.value}
              shop={shop}
              name={catalog.value}
              component={ProductCard}
              size={isDesktop ? 6 : 5}
              props={props}
            />
          ))}
        </>
      )}
      {showTemplate && <Templates templates={foundProductsTemplates} shop={shop}/>}
    </div>
  );
}

LilacStore.propTypes = props;
LilacStore.defaultProps = defaultProps;

export default LilacStore;
