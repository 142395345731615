import PropTypes from 'prop-types';
import ShowMoreText from 'react-show-more-text';
import cx from 'classnames';
import { useState } from 'react';
import styles from './common.module.css';

function StoreDescription({ shop, lines }) {

  const [expanded, setExpanded] = useState(false);

  const executeOnClick = (value) => {

    setExpanded(value);
  
  };

  return (
    <ShowMoreText
      lines={lines}
      more="More"
      less="Less"
      className={cx('fs12', styles.rte)}
      anchorClass={styles.anchor}
      onClick={executeOnClick}
      expanded={expanded}
    >
      <div
        dangerouslySetInnerHTML={{ __html: shop?.description }}
      >
      </div>
    </ShowMoreText>

  );

}

StoreDescription.propTypes = {
  shop: PropTypes.object.isRequired,
  lines: PropTypes.number,
};

StoreDescription.defaultProps = {
  lines: 2,
};

export default StoreDescription;
