import PropTypes from 'prop-types';
import cx from 'classnames';
import { Clickable } from 'phoenix-components';
import { Avatar } from '@mui/material';
import { randColor } from 'ui/lib/colors';
import { CatalogMoreIcon, SlideNextIcon, SlidePrevIcon } from 'components/shared/Icons/carnation';
import React, { useState } from 'react';
import { useRouter } from 'next/router';
import { useLanguageLoader } from 'contexts/language';
import { useIsCustomDomain } from 'contexts/profile';
import { ViewAllIconTop } from 'components/shared/Icons/theme';
import styles from './common.module.css';
import { Container } from 'components/layout/Container';
import { useKeenSlider } from 'keen-slider/react';

export default function Catalogs({
  catalogs,
  shop,
}) {

  const router = useRouter();
  const lang = useLanguageLoader();
  const isCustomDomain = useIsCustomDomain();
  const [currentSlide, setCurrentSlide] = useState(0);


  const [sliderRef, instanceRef] = useKeenSlider(
    {
      slideChanged(slider) {
        setCurrentSlide(slider.track.details.rel);
      },
      slides: {
        perView: 4,
        spacing: 6,
        origin: catalogs.length < 4 ? 'center' : undefined,
      }
    },
  );

  const openCatalog = name => () => {

    router.push(
      {
        pathname: isCustomDomain ? '/catalogs' : `/${shop?.slug}/catalogs`,
        query: {
          catalog: name
        },
      }
    );

  };

  if (catalogs.length <= 0) {

    return null;

  }

  const showButtons = catalogs.length > 4;

  return (
    <Container>
      <div className={cx('mobile')}>
        <div className={styles.heading}>Collections</div>
        <div
          className={cx(styles.catalogs, { 'vertical-center': catalogs.length === 1 })}
        >
          {catalogs.slice(0, 4)
            .map(catalog => (
              <Clickable
                key={catalog.label}
                className={styles.catalog}
                onClick={openCatalog(catalog.value)}
              >
                <div className={styles.catalogInner}>
                  <Avatar
                    className={cx(styles.catalogImg, {
                      [styles.noCatalogImg]: !catalog.img,
                    })}
                    style={{
                      background: randColor(catalog.label),
                    }}
                    src={catalog.img}
                    imgProps={{
                      loading:'lazy',
                    }}
                    alt=""
                  >
                    {catalog?.label ? catalog.label.substring(0, 2) : 'NA'}
                  </Avatar>
                </div>
                <div className={styles.catalogLabel}>
                  {catalog.label}
                </div>
              </Clickable>
            ))}
          {catalogs?.length > 4 && (
            <Clickable
              className={cx(styles.catalogMore)}
              onClick={() => {

                router.push(isCustomDomain ? '/catalogList' : `${shop?.slug}/catalogList`);

              }}
            >
              <span>
                {lang('store.headings.viewAll')}
              </span>
              <ViewAllIconTop />
            </Clickable>
          )}
        </div>
      </div>
      <div className="desktop">
        <div className={styles.catalogs}>
          {showButtons && (
            <Clickable
              className={cx(styles.slideButton, {
                'opacity-50': currentSlide === 0,
              })}
              
              onClick={() => {
                instanceRef.current.prev();
              }}
            >
              <SlidePrevIcon />
            </Clickable>
          )}
          <div
            ref={sliderRef}
            className={'keen-slider'}
          >
            {catalogs.slice(0, 8)
              .map(catalog => (
                <div
                  key={catalog.label}
                  className={cx({ [styles.single]: catalogs.length === 1 }, 'keen-slider__slide')}
                >
                  <Clickable
                    className={styles.catalog}
                    onClick={openCatalog(catalog.value)}
                  >
                    <div className={styles.catalogInner}>
                      <Avatar
                        className={cx(styles.catalogImg, {
                          [styles.noCatalogImg]: !catalog.img,
                        })}
                        style={{
                          background: randColor(catalog.label),
                        }}
                        src={catalog.img}
                        alt=""
                        imgProps={{
                          loading:'lazy',
                        }}
                      >
                        {catalog?.label ? catalog.label.substring(0, 2) : 'NA'}
                      </Avatar>
                    </div>
                    <div className={styles.catalogLabel}>
                      {catalog.label}
                    </div>
                  </Clickable>
                </div>
              ))}
            {catalogs?.length > 8 && (
              <div className={'keen-slider__slide'}>
                <Clickable
                  className={cx(styles.catalog)}
                  onClick={() => {

                    router.push(isCustomDomain ? '/catalogList' : `${shop?.slug}/catalogList`);

                  }}
                >
                  <div className={styles.catalogMore}>
                    <CatalogMoreIcon />
                  </div>
                  <div className={styles.catalogLabel}>
                    {lang('store.headings.viewAll')}
                  </div>
                </Clickable>
              </div>
            )}
          </div>
          {showButtons && (
            <Clickable
              className={cx(styles.slideButton, {
                'opacity-30': currentSlide === catalogs.length - 1,
              })}
              onClick={() => {

                instanceRef.current.next();

              }}>
              <SlideNextIcon />
            </Clickable>
          )}
        </div>
      </div>
    </Container>

  );

}

Catalogs.propTypes = {
  catalogs: PropTypes.array.isRequired,
  shop: PropTypes.object.isRequired,
};
